'use client';
import React, { useRef } from 'react';
import Image from 'next/image';
import { IKButton } from '@/components/shared/material';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from './PortableTextComponents';
import { m, useInView } from 'framer-motion';
import { fadeUp } from '@/assets/framer/animations';
import { MenuItemLink } from '@/types/types';

export default function StoreBanner(props: {
  data?: {
    _key?: string;
    sectionId?: string;
    heading?: PortableTextBlock;
    subheading?: PortableTextBlock;
    buttons?: [
      {
        text?: string;
        link: MenuItemLink;
      },
    ];
    image?: {
      url?: string;
      alt?: string;
      placeholderUrl?: string;
    };
    mobileImage?: {
      url?: string;
      alt?: string;
      placeholderUrl?: string;
    };
  };
}) {
  // Props
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const buttons = props.data?.buttons;
  const subheading = props.data?.subheading;
  const image = props.data?.image;
  const mobileImage = props.data?.mobileImage;
  const headingRef = useRef(null);
  const section = useRef(null);
  const sectionKey = props.data?._key;

  const isInView = useInView(headingRef, { amount: 0.4, once: true });

  return (
    <section
      id={sectionId}
      ref={section}
      className='section-padding relative scroll-m-16 !pb-0'
    >
      <div className='container-padding container relative mx-auto'>
        <div className='grid gap-10 pt-10 sm:pt-0 md:grid-cols-4'>
          <div className='relative order-2 flex items-center md:order-1 md:col-span-2 md:-mt-12'>
            <div>
              <m.h2
                initial='hidden'
                animate={isInView ? 'visible' : 'hidden'}
                variants={fadeUp}
                ref={headingRef}
                className='heading-xl relative z-10 pb-4 font-display font-medium tracking-tighter text-charcoal sm:max-w-[700px] [&_strong]:text-pink'
              >
                {heading && (
                  <PortableText
                    components={components}
                    value={heading}
                  ></PortableText>
                )}
              </m.h2>
              <p className='mb-3 mt-0 pb-3 text-xl leading-8 md:pb-4'>
                {subheading && (
                  <PortableText
                    components={components}
                    value={subheading}
                  ></PortableText>
                )}
              </p>

              {/* Action buttons */}
              <div className='flex items-center gap-10 md:pt-6'>
                {buttons &&
                  buttons.length > 0 &&
                  buttons.map((button, index) => (
                    <IKButton
                      key={`${sectionKey}-store-button-${index}`}
                      link={button.link}
                      color={'charcoal'}
                      variant={index === 1 ? 'text' : 'filled'}
                      size={index === 1 ? 'sm' : 'md'}
                      className={`flex items-center gap-1 ${
                        index === 1
                          ? 'bg-gradient-line relative overflow-x-hidden !rounded-none bg-right-bottom bg-no-repeat !py-2 transition-all duration-500 hover:!bg-left-bottom hover:transition-all hover:duration-500'
                          : 'px-8'
                      }`}
                    >
                      <span>{button.text}</span>
                      {index === 0 && (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='25'
                          height='25'
                          viewBox='0 0 25 25'
                          fill='none'
                        >
                          <path
                            d='M17.5854 12.5703L10.0854 20.0704C10.0157 20.14 9.93302 20.1953 9.84197 20.233C9.75093 20.2707 9.65335 20.2901 9.5548 20.2901C9.45625 20.2901 9.35867 20.2707 9.26763 20.233C9.17658 20.1953 9.09386 20.14 9.02417 20.0704C8.95449 20.0007 8.89922 19.9179 8.8615 19.8269C8.82379 19.7359 8.80438 19.6383 8.80438 19.5397C8.80438 19.4412 8.82379 19.3436 8.8615 19.2526C8.89922 19.1615 8.95449 19.0788 9.02417 19.0091L15.9945 12.0397L9.02417 5.07035C8.88344 4.92962 8.80438 4.73875 8.80438 4.53972C8.80438 4.3407 8.88344 4.14983 9.02417 4.0091C9.1649 3.86837 9.35578 3.78931 9.5548 3.78931C9.75382 3.78931 9.94469 3.86837 10.0854 4.0091L17.5854 11.5091C17.6552 11.5788 17.7105 11.6615 17.7482 11.7525C17.786 11.8436 17.8054 11.9412 17.8054 12.0397C17.8054 12.1383 17.786 12.2359 17.7482 12.3269C17.7105 12.418 17.6552 12.5007 17.5854 12.5703Z'
                            fill='currentColor'
                          />
                        </svg>
                      )}
                    </IKButton>
                  ))}
              </div>
            </div>
          </div>
          <div className='relative order-1 flex items-center justify-center md:col-span-2 xl:order-2'>
            <div className='aspect-square hidden md:flex md:w-full xl:w-3/4'>
              {image && (
                <Image
                  src={image.url || ''}
                  alt={image.alt || ''}
                  height={500}
                  width={500}
                  placeholder='blur'
                  blurDataURL={image.placeholderUrl || ''}
                />
              )}
            </div>
            <div className='aspect-square flex w-full md:hidden'>
              {mobileImage && (
                <Image
                  src={mobileImage.url || ''}
                  alt={mobileImage.alt || ''}
                  height={500}
                  width={500}
                  className='rounded-2xl'
                  placeholder='blur'
                  blurDataURL={mobileImage.placeholderUrl || ''}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
