import { PortableText } from '@portabletext/react';
import Image from 'next/image';
import { PortableTextBlock } from 'sanity';
import Pattern from '@/public/images/Pattern.png';
import { components } from './PortableTextComponents';

export default function TrustSignalsStrip(props: {
  data?: {
    sectionId?: string;
    heading?: PortableTextBlock;
    subtext?: PortableTextBlock;
    image?: {
      url: string;
      alt: string;
      blurDataUrl: string;
    };
  };
}) {
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const subtext = props.data?.subtext;
  const image = props.data?.image;

  return (
    <>
      <section
        id={sectionId}
        className='section-padding scroll-m-16 bg-gradient-to-b from-white via-yellow-50 to-white'
      >
        <div className='container-padding relative z-10 flex justify-center'>
          {heading && (
            <h2 className='text-medium mb-5 max-w-[280px] text-center text-xl'>
              <PortableText
                value={heading}
                components={components}
              ></PortableText>
            </h2>
          )}
        </div>
        <div className='relative flex items-center justify-center py-3'>
          <div className='h-40`123456789+ 4 *:first: z-10 rounded-3xl bg-white px-8 py-4'>
            {image && image.url && (
              <Image
                src={image.url}
                alt={image.alt ?? ''}
                width={320}
                height={75}
              />
            )}
          </div>
          <div className='absolute inset-x-0 h-full overflow-hidden bg-yellow-100/80 opacity-60'>
            <Image
              className='h-full w-full min-w-[1920px] object-cover'
              src={Pattern}
              alt={''}
              width={1920}
              height={100}
              loading='lazy'
            />
          </div>
        </div>
        <div className='container-padding flex justify-center pb-10 pt-3 md:pt-5'>
          {subtext && (
            <p className='max-w-[420px] text-center text-xs text-charcoal'>
              <PortableText
                value={subtext}
                components={components}
              ></PortableText>
            </p>
          )}
        </div>
      </section>
    </>
  );
}
