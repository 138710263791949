'use client';
import React, { useRef } from 'react';
import Image from 'next/image';
import { IKButton } from '@/components/shared/material';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components, paragraphs } from './PortableTextComponents';
import { m, useInView } from 'framer-motion';
import { fadeUp, fadeUpLeft } from '@/assets/framer/animations';
import { MenuItemLink } from '@/types/types';

export default function InformationBanner(props: {
  data?: {
    _id: number;
    sectionId?: string;
    heading?: PortableTextBlock;
    body: PortableTextBlock;
    color?: {
      label: string;
      value: string;
    };
    buttons?: [
      {
        text?: string;
        link: MenuItemLink;
      },
    ];
    image?: {
      url?: string;
      alt?: string;
      placeholderUrl?: string;
    };
    placeholderImage?: string;
    backgroundImage?: string;
  };
}) {
  // Props

  const sectionId = props.data?.sectionId;
  const title = props.data?.heading;
  const buttons = props.data?.buttons;
  const image = props.data?.image;
  const body = props.data?.body;

  const section = useRef(null);
  const isInView = useInView(section, { amount: 0.4, once: true });

  return (
    <m.section
      id={sectionId}
      ref={section}
      className='section-padding relative z-30 mb-40 scroll-m-16 lg:mb-20'
    >
      <div className='container-padding container'>
        <div
          className={`relative mx-auto rounded-[24px] border-2 border-yellow-500 shadow-[-10px_11px_28px_0px_var(--tw-shadow-color)] shadow-yellow/10`}
        >
          <div className='flex flex-col items-center justify-between gap-5 px-6 pb-8 pt-6 sm:px-10 sm:py-6 md:flex-row md:p-12 md:py-12'>
            <div className='flex flex-col items-center gap-5 md:w-full md:flex-row md:gap-10'>
              <div className='text-left md:w-full'>
                <m.div
                  initial='hidden'
                  animate={isInView ? 'visible' : 'hidden'}
                  variants={fadeUp}
                  transition={{ delay: 0.1, type: 'tween' }}
                  className='text-lg font-bold sm:text-xl md:mb-5 md:text-2xl lg:max-w-[60%] lg:text-[28px] [&_strong]:text-pink'
                >
                  {title && (
                    <PortableText
                      components={components}
                      value={title}
                    ></PortableText>
                  )}
                </m.div>
                <m.div
                  initial='hidden'
                  animate={isInView ? 'visible' : 'hidden'}
                  variants={fadeUp}
                  transition={{ delay: 0.1, type: 'tween' }}
                  className='text-lg md:mb-5 md:max-w-[60%] md:text-2xl [&_strong]:text-pink'
                >
                  {body && (
                    <PortableText
                      components={paragraphs}
                      value={body}
                    ></PortableText>
                  )}
                </m.div>

                <m.div
                  initial='hidden'
                  animate={isInView ? 'visible' : 'hidden'}
                  variants={fadeUp}
                  transition={{ delay: 0.2 }}
                  className='flex flex-col gap-3 md:flex-row md:items-center'
                >
                  {buttons &&
                    buttons.length > 0 &&
                    buttons.map((button, index) => (
                      <IKButton
                        key={`button-${index}`}
                        link={button.link}
                        color='teal'
                        variant='text'
                        size={index === 1 ? 'md' : 'sm'}
                        ripple={false}
                        className='group flex items-center gap-2 text-left'
                      >
                        <span>{button.text}</span>
                        <svg
                          className='inline-block transition duration-500 group-hover:translate-x-2 [&_path]:fill-teal'
                          xmlns='http://www.w3.org/2000/svg'
                          width='17'
                          height='16'
                          viewBox='0 0 17 16'
                          fill='none'
                        >
                          <path
                            d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                            fill='none'
                          />
                        </svg>
                      </IKButton>
                    ))}
                </m.div>
              </div>
            </div>
          </div>

          <div className='absolute right-0 top-[75%] sm:top-[65%] md:right-0 md:top-[45%] lg:top-0 xs:-right-2'>
            {/* Floating patterns */}
            {/* Teal pattern */}
            <div className='absolute -left-24 bottom-10 sm:-left-28 sm:bottom-5 md:bottom-20 xl:bottom-32'>
              <div className='relative bottom-0 right-0'>
                <m.svg
                  initial='hidden'
                  variants={fadeUpLeft}
                  transition={{
                    delay: 0.3,
                    type: 'tween',
                  }}
                  xmlns='http://www.w3.org/2000/svg'
                  className='z-20 h-16 w-16 sm:h-20 sm:w-20 md:z-0'
                  width='80'
                  height='80'
                  viewBox='0 0 67 67'
                  fill='none'
                  animate={{
                    ...(isInView
                      ? {
                          opacity: 1,
                        }
                      : fadeUp.hidden),
                  }}
                >
                  <path
                    d='M26.8986 27.3153C27.1852 27.3153 27.4145 27.0858 27.4145 26.7989V14.655H39.9672V26.7989C39.9672 27.0858 40.1965 27.3153 40.4831 27.3153H52.176V39.8226H40.0436C39.757 39.8226 39.5278 40.0521 39.5278 40.339V52.7889H27.7202V40.3963C27.7202 40.1095 27.4909 39.88 27.2043 39.88H14.7471V27.3153H26.8604H26.8986ZM1.25824 40.6258H13.7154V53.4009C13.7154 53.6878 13.9447 53.9173 14.2313 53.9173H26.8986V66.3098C26.8986 66.5967 27.1279 66.8262 27.4145 66.8262H40.4066C40.6932 66.8262 40.9225 66.5967 40.9225 66.3098V53.8408H53.0549C53.3415 53.8408 53.5707 53.6113 53.5707 53.3244V40.5493H65.7031C65.9897 40.5493 66.219 40.3198 66.219 40.033V27.0475C66.219 26.7607 65.9897 26.5312 65.7031 26.5312H54.0102V13.8135C54.0102 13.5266 53.7809 13.2971 53.4943 13.2971H40.7314V1.13403C40.7314 0.847168 40.5022 0.617676 40.2156 0.617676H27.2425C26.9559 0.617676 26.7267 0.847168 26.7267 1.13403V13.2589H13.9638C13.6772 13.2589 13.4479 13.4884 13.4479 13.7752V26.5503H1.25824C0.971644 26.5503 0.742371 26.7798 0.742371 27.0667V40.0521C0.742371 40.339 0.971644 40.5685 1.25824 40.5685'
                    fill='#009EAC'
                  />
                </m.svg>
              </div>
              {/* Yellow pattern */}
              <div className='absolute -left-12 bottom-14 sm:-left-10 sm:bottom-16'>
                <m.svg
                  initial='hidden'
                  variants={fadeUpLeft}
                  transition={{
                    delay: 0.3,
                    type: 'tween',
                  }}
                  xmlns='http://www.w3.org/2000/svg'
                  className='z-0 h-10 w-10 sm:h-12 sm:w-12'
                  width='41'
                  height='40'
                  viewBox='0 0 41 40'
                  fill='none'
                  animate={{
                    ...(isInView
                      ? {
                          opacity: 1,
                        }
                      : fadeUp.hidden),
                  }}
                >
                  <path
                    d='M26.4013 25.7783H14.2307V14.3611H26.4013V25.7783ZM0.703548 27.5378H13.3518V39.2801C13.3518 39.567 13.5811 39.7965 13.8677 39.7965H26.8407C27.1273 39.7965 27.3566 39.567 27.3566 39.2801V26.6007H39.8711C40.1576 26.6007 40.3869 26.3712 40.3869 26.0843V13.0798C40.3869 12.7929 40.1576 12.5634 39.8711 12.5634H27.2228V0.821045C27.2228 0.53418 26.9935 0.304688 26.707 0.304688H13.7339C13.4473 0.304688 13.218 0.53418 13.218 0.821045V13.5005H0.703548C0.416956 13.5005 0.187683 13.73 0.187683 14.0168V27.0023C0.187683 27.2892 0.416956 27.5186 0.703548 27.5186'
                    fill='#FFCD00'
                  />
                </m.svg>
              </div>
            </div>
            {image && image.url && (
              <m.div
                initial='hidden'
                animate={isInView ? 'visible' : 'hidden'}
                variants={fadeUp}
                transition={{ delay: 0.1, type: 'tween' }}
                className='shadow-black/20 drop-shadow-[-10px_11px_28px_var(--tw-shadow-color)]'
              >
                <Image
                  alt={image.alt ?? ''}
                  width='450'
                  height='450'
                  placeholder='blur'
                  blurDataURL={image.placeholderUrl}
                  src={image.url}
                  className={`aspect-square relative top-7 z-10 block h-[180px] w-[180px] object-cover [clip-path:_url(#mask-info-card)] sm:h-[180px] sm:w-[180px] md:h-[250px] md:w-[250px] lg:h-[400px] lg:w-[400px]`}
                />
                <svg xmlns='http://www.w3.org/2000/svg' width='0' height='0'>
                  <defs>
                    <clipPath
                      id={`mask-info-card`}
                      clipPathUnits='objectBoundingBox'
                      transform='scale(1.5, 1.48)'
                    >
                      <path d='M0.0800267 0.624263L0.521012 0.675214C0.564599 0.67505 0.6 0.639539 0.6 0.595917V0.0792976C0.6 0.0356748 0.564599 0.000164064 0.521012 0L0.0799538 0.0509692C0.0321565 0.0568937 0 0.0887768 0 0.130303V0.544911C0 0.565857 0.00754694 0.584268 0.0218205 0.598122C0.0358389 0.61174 0.055964 0.620782 0.0800267 0.624263Z' />
                    </clipPath>
                  </defs>
                </svg>
              </m.div>
            )}
          </div>
        </div>
      </div>
    </m.section>
  );
}
