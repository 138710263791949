'use client';
import React, { useEffect, useRef, useState } from 'react';
import { Scrollbar, A11y, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { PortableTextBlock } from 'sanity';
import { m, useInView } from 'framer-motion';
import { moveUp } from '@/assets/framer/animations';
import { MenuItemLink } from '@/types/types';
import { SlimCard, StandardCard } from '@/components/shared/cards';

export type CardType = {
  title?: PortableTextBlock;
  description?: PortableTextBlock;
  image?: {
    url?: string;
    alt?: string;
    placeholderUrl?: string;
  };
  button: {
    text: string;
    link?: MenuItemLink;
  };
};

export default function CardSlider(props: {
  cards?: CardType[];
  cardType?: 'slim' | 'standard';
}) {
  const [, setSwiper] = useState<SwiperClass | null>(null);
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { amount: 0.5, once: true });
  const cards = props.cards ?? [];
  const cardType = props.cardType ?? 'slim';

  const paginationContainer = useRef<HTMLDivElement>(null);
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  const [activeVideo, setActiveVideo] = useState<string | null>(null);

  const paginationBulletClass =
    'p-1 !w-4 duration-300 cursor-pointer !h-4 flex !rounded-full items-center [&.swiper-pagination-bullet-active]:!bg-yellow justify-center outline-none !bg-transparent border-2 border-yellow !opacity-100';
  const slideClass = `rounded-lg overflow-hidden bg-transparent !overflow-visible !h-[unset] flex-grow !w-[unset] asepect-square 2xl:max-w-[450px] lg:max-w-[420px] sm:max-w-[350px] max-w-[315px]`;
  const paginationButtonClass = `disabled:opacity-0 cursor-pointer [&_path]:fill-black fill-black h-12 w-12 shadow-lg absolute rounded-full bg-yellow hover:bg-yellow-700 flex items-center justify-center hover:scale-105 duration-100 transform z-20 p-2.5`;
  const leftButton = `${paginationButtonClass} left-0`;
  const rightButton = `${paginationButtonClass} right-0`;

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (
        (event.key === 'Escape' && activeVideo) ||
        (event.key === 'Esc' && activeVideo)
      ) {
        setActiveVideo(null);
      }
    };

    window.addEventListener('keydown', handleEscKeyPress);

    return () => {
      window.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [activeVideo]);

  const [, setActiveSlide] = useState<number>(0);

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveSlide(swiper.activeIndex);
  };

  return (
    <>
      <m.div
        ref={sectionRef}
        className='relative z-10 pt-10'
        initial='outOfView'
        animate={isInView ? 'visible' : 'outOfView'}
        variants={moveUp}
      >
        <Swiper
          className='!overflow-visible hover:cursor-grab active:cursor-grabbing [&>.swiper-wrapper]:mb-5 [&>.swiper-wrapper]:space-x-5 [&>.swiper-wrapper]:overflow-visible lg:[&>.swiper-wrapper]:space-x-8 sm:[&_.swiper-pagination]:!bottom-8'
          modules={[Navigation, Scrollbar, A11y, Pagination, Pagination]}
          onInit={(swiper: SwiperClass) => {
            setSwiper(swiper);
          }}
          onSlideChange={handleSlideChange}
          breakpoints={{
            0: {
              slidesPerView: 'auto',
              spaceBetween: 0,
            },
            // when window width is >= 425px
            425: {
              slidesPerView: 'auto',
              spaceBetween: 0,
            },
            // when window width is >= 765px
            765: {
              slidesPerView: 'auto',
              spaceBetween: 0,
            },
            // when window width is >= 980px
            980: {
              slidesPerView: 'auto',
              spaceBetween: 0,
            },
          }}
          navigation={{
            enabled: cardType === 'standard',
            prevEl: prevRef.current,
            nextEl: nextRef.current,
            disabledClass: 'disabled',
          }}
          pagination={{
            clickable: true,
            el: paginationContainer.current,
            renderBullet: function (_index: number, className: string) {
              return (
                '<span class="' +
                className +
                ' ' +
                paginationBulletClass +
                '"></span>'
              );
            },
          }}
        >
          {cards.length > 0 &&
            cards.map((card, index) => (
              <SwiperSlide
                className={slideClass}
                key={`home-carousel-${index}`}
              >
                {cardType === 'slim' ? (
                  <SlimCard card={card} />
                ) : (
                  <StandardCard card={card} />
                )}
              </SwiperSlide>
            ))}

          {/* Navigation */}
          {cardType === 'standard' && (
            <div className='absolute left-0 top-[50%] z-40 block w-full -translate-y-8 transform'>
              <button ref={prevRef} className={leftButton}>
                <svg
                  className='h-5 w-5 rotate-180 transform'
                  xmlns='http://www.w3.org/2000/svg'
                  width='17'
                  height='16'
                  viewBox='0 0 17 16'
                  fill='none'
                >
                  <path
                    d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                    fill='none'
                  />
                </svg>
              </button>
              <button ref={nextRef} className={rightButton}>
                <svg
                  className='h-5 w-5'
                  xmlns='http://www.w3.org/2000/svg'
                  width='17'
                  height='16'
                  viewBox='0 0 17 16'
                  fill='none'
                >
                  <path
                    d='M9.81223 15.4466L16.2143 9.0506C16.3186 8.95578 16.4019 8.84051 16.4589 8.7121C16.5159 8.58369 16.5453 8.44494 16.5453 8.30466C16.5453 8.16438 16.5159 8.02564 16.4589 7.89723C16.4019 7.76881 16.3186 7.65354 16.2143 7.55872L9.81223 1.16268C9.62179 0.977924 9.37156 0.865835 9.10577 0.84623C8.83997 0.826625 8.57574 0.900765 8.35979 1.05554C8.23002 1.14171 8.12192 1.25621 8.04381 1.39025C7.96569 1.52429 7.91964 1.67429 7.9092 1.82873C7.89876 1.98316 7.9242 2.13791 7.98357 2.28108C8.04294 2.42424 8.13466 2.55201 8.25167 2.65456L12.8785 7.23732L1.47524 7.23732C1.19718 7.248 0.934093 7.36495 0.741174 7.56365C0.548254 7.76235 0.440491 8.02735 0.440491 8.30306C0.440491 8.57878 0.548254 8.84378 0.741174 9.04248C0.934093 9.24118 1.19718 9.35813 1.47524 9.3688L12.8801 9.3688L8.25328 13.9532C8.13627 14.0557 8.04455 14.1835 7.98518 14.3266C7.92581 14.4698 7.90037 14.6246 7.91081 14.779C7.92126 14.9334 7.96731 15.0834 8.04542 15.2175C8.12354 15.3515 8.23164 15.466 8.36141 15.5522C8.57736 15.707 8.84159 15.7811 9.10738 15.7615C9.37317 15.7419 9.62341 15.6298 9.81385 15.445L9.81223 15.4466Z'
                    fill='none'
                  />
                </svg>
              </button>
            </div>
          )}
        </Swiper>
      </m.div>
      <div className='relative z-10 py-5'>
        <div
          className='flex items-center justify-center'
          ref={paginationContainer}
        ></div>
      </div>
    </>
  );
}
