'use client';
import React, { useEffect, useMemo, useRef } from 'react';
import Image from 'next/image';
import { Card, IKButton } from '@/components/shared/material';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from '@/components/sections';
import { m, useInView } from 'framer-motion';
import { MenuItemLink } from '@/types/types';
import { formatPriceWithoutCents } from '@/sanity/utils/shopify/priceHelpers';
import { mixpanelProductImpression } from '@/lib/mixpanel/services/client';
import { IKPattern } from '../patterns';
import { useStatsigClient } from '@statsig/react-bindings';
import PriceSlash from '../PriceSlash';

export type FeatureCardType = {
  image?: {
    url?: string;
    alt?: string;
    placeholderUrl?: string;
  };
  product?: {
    sanityTitle: string;
    slug: string;
    categorySlug: string;
    price: number;
    compareAtPrice?: number;
    variantGid: string;
    previewImageUrl: string;
    images: [
      {
        url: string;
        alt?: string;
        blurDataURL: string;
      },
    ];
  };
  backgroundImage: {
    url?: string;
  };
  color?: {
    label: string;
    value: string;
  };
  title?: PortableTextBlock;
  learnMoreButton: {
    text: string;
    link?: MenuItemLink;
  };
  buyButton: {
    text: string;
    link?: MenuItemLink;
  };
  lineItems?: {
    title: string;
    value: string;
    subtext: string;
    productPrice: boolean;
  }[];
  useProductTitle?: boolean;
};

const fadeUp = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export default function FeatureProductCardDetail(props: {
  card: FeatureCardType;
  index: number;
}) {
  const card = props.card;
  const index = props.index;
  const ref = useRef(null);
  const isInViewMixpanel = useInView(ref, { amount: 0.5, once: true });
  const isInViewInital = useInView(ref, { amount: 0.2, once: true });
  const cardColour = card.color?.label.toLowerCase() ?? 'charcoal';
  const { logEvent } = useStatsigClient();

  // Track product impression
  useEffect(() => {
    if (isInViewMixpanel && card && card.product) {
      mixpanelProductImpression({ product: card.product });
      logEvent('product_impression', card.product.sanityTitle, {
        event_source: 'Online',
        currency_code: 'ZAR',
        price: `${card.product.price}`,
        name: card.product.sanityTitle,
        category: card.product.categorySlug?.replace(/-/g, ' ') ?? '',
      });
    }
  }, [isInViewMixpanel, card]);

  const SVGPatternColor = useMemo(() => {
    let colorClass = '';
    switch (cardColour) {
      case 'teal':
        colorClass = '[&_path]:fill-teal-700 fill-teal-700';
        break;
      case 'pink':
        colorClass = 'fill-pink-800 [&_path]:fill-pink-800';
        break;
      case 'charcoal':
        colorClass = 'fill-charcoal-800 [&_path]:fill-charcoal-800';
        break;
      case 'orange':
        colorClass = 'fill-orange-700 [&_path]:fill-orange-700';
        break;
      default:
        break;
    }
    return colorClass;
  }, [cardColour]);

  return (
    <m.div
      ref={ref}
      // initial='hidden'
      animate={isInViewInital ? 'visible' : 'hidden'}
      variants={fadeUp}
      transition={{ type: 'spring', stiffness: 100 }}
      aria-hidden
      className='group relative flex h-full w-full flex-col'
    >
      <Card
        className={`relative z-10 flex flex-grow rounded-3xl border bg-gradient-to-b shadow-none ${
          cardColour === 'orange' ? 'bg-gradient-orange border-orange-600' : ''
        } ${cardColour === 'teal' ? 'bg-gradient-teal border-teal-600' : ''} ${
          cardColour === 'pink' ? 'bg-gradient-dark-pink border-pink-600' : ''
        } `}
      >
        {/* Background SVG pattern */}
        <div className='absolute left-0 top-0 z-0 h-full w-full overflow-hidden'>
          <IKPattern
            color={cardColour as 'orange' | 'teal' | 'pink' | 'charcoal'}
            patternId={index}
            pattternProps={{
              className: `${SVGPatternColor}`,
            }}
            className={`absolute left-0 top-0 z-0 h-[2000px] translate-y-[-20%] rounded-3xl opacity-[15%] ${SVGPatternColor}`}
          />
        </div>

        <div className='z-10 divide-y divide-white/40 px-8 pb-7 pt-5'>
          <div className='flex items-center gap-3 pb-2'>
            {/* Card Image */}
            {card.image && card.image.url && (
              <div className='z-10'>
                <Image
                  quality={100}
                  className={`mx-auto max-w-none`}
                  src={card.image?.url}
                  width={75}
                  height={75}
                  alt={card.image.alt ?? ''}
                />
              </div>
            )}

            <div className='text-2xl font-bold text-white'>
              {card.title && !card.useProductTitle && (
                <PortableText
                  components={components}
                  value={card.title}
                ></PortableText>
              )}

              {card.useProductTitle && card.product?.sanityTitle}
            </div>
          </div>
          {card.lineItems && card.lineItems.length > 0 && (
            <div className='pt-4'>
              {card.lineItems?.map((item, index) => (
                <div
                  key={index}
                  className='flex flex-wrap items-center justify-between py-1 sm:text-nowrap'
                >
                  <div className='w-1/2 text-base text-white'>{item.title}</div>
                  <div className='w-1/2 text-right text-base font-bold text-white'>
                    {item.productPrice && card.product?.price ? (
                      <div className='flex items-center justify-end gap-3'>
                        {card.product?.compareAtPrice && (
                          <PriceSlash
                            className='font-normal'
                            priceSlashProps={{ className: 'bg-white' }}
                            compareAtPrice={card.product.compareAtPrice}
                          />
                        )}
                        <span>
                          {formatPriceWithoutCents(card.product?.price)}
                        </span>
                      </div>
                    ) : (
                      item.value
                    )}
                  </div>
                  <div className='flex-grow whitespace-pre text-xs text-white/80'>
                    {item.subtext}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Card>

      {/* Footer */}
      <div
        className={`z-0 -translate-y-10 transform overflow-hidden rounded-3xl ${cardColour === 'orange' ? 'bg-[#73220C]' : ''} ${cardColour === 'teal' ? 'bg-teal-800' : ''} ${cardColour === 'pink' ? 'bg-pink-850' : ''} `}
      >
        <div className='flex w-full items-center [&>a]:!w-1/2 [&>a]:!flex-grow'>
          <IKButton
            link={card.learnMoreButton.link}
            className={`flex-grow !rounded-none !bg-transparent pb-4 pt-14 text-center text-sm font-semibold uppercase tracking-[1px] !shadow-none hover:!ring-0 sm:text-base lg:pb-5 lg:pt-16 ${
              cardColour === 'orange'
                ? 'text-orange-400 hover:!bg-white/[5%]'
                : ''
            } ${
              cardColour === 'teal' ? 'text-teal-600 hover:!bg-white/[5%]' : ''
            } ${
              cardColour === 'pink' ? 'text-pink-400 hover:!bg-white/[5%]' : ''
            } `}
          >
            {card.learnMoreButton.text}
          </IKButton>
          {/* Separator line */}
          <span
            className={`mt-10 h-[30px] w-[1px] self-center opacity-30 ${cardColour === 'orange' ? 'bg-orange-300' : ''} ${cardColour === 'teal' ? 'bg-teal-300' : ''} ${cardColour === 'pink' ? 'bg-pink-300' : ''} `}
          ></span>
          <IKButton
            link={card.buyButton.link}
            className={`w-1/2 flex-grow !rounded-none !bg-transparent pb-4 pt-14 text-center text-sm font-extrabold uppercase tracking-[1px] !shadow-none hover:!ring-0 sm:text-base lg:pb-5 lg:pt-16 ${
              cardColour === 'orange'
                ? 'text-orange-200 hover:!bg-orange-600 hover:!text-[#73220C]'
                : ''
            } ${
              cardColour === 'teal'
                ? 'text-teal-100 hover:!bg-teal-600 hover:!text-teal-800'
                : ''
            } ${
              cardColour === 'pink'
                ? 'text-pink-200 hover:!bg-pink-600 hover:!text-pink-850'
                : ''
            } `}
          >
            {card.buyButton.text}
          </IKButton>
        </div>
      </div>
    </m.div>
  );
}
