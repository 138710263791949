import HomeBanner from './HomeBanner';
import CategoryBanner from './CategoryBanner';
import FeatureProductCards from './FeatureProductCards';
import TwoColTextImage from './TwoColTextImage';
import HomeCarousel from './HomeCarousel';
import FeatureList from './FeatureList';
import FeatureCardGrid from './FeatureCardGrid';
import CompanyStatsHighlightPanel from './CompanyStatsHighlightPanel';
import InformationBanner from './InformationBanner';
import InformationCard from './InformationCard';
import Form from './Form';
import ProductFeatureBanner from './shop/ProductFeatureBanner';
import AppBanner from './AppBanner';
import StoreBanner from './StoreBanner';
import UniqueSellingPointsBanner from './UniqueSellingPointsBanner';
import CompareProductsTable from './CompareProductsTable';
import ShopBySwiper from './ShopBySwiper';
import ProductBanner from './shop/ProductBanner';
import ProductVideoAninmation from './shop/ProductVideoAninmation';
import CardsSection from './CardsSection';
import TwoColTextMediaCard from './TwoColTextMediaCard';
import TrustSignalsStrip from './TrustSignalsStrip';
import WhatsInTheBox from './shop/WhatsInTheBox';
import PricingCalculator from './PricingCalculator';
import ShopNavigationAnchorBanner from './ShopNavigationAnchorBanner';
import ProductCardGrid from './ProductCardGrid';
import Heading from './Heading';
import FAQ from './FAQ';
import ContactBlock from './ContactBlock';
import ProductSpotlight from './shop/ProductSpotlight';
import PageBanner from './PageBanner';
import TestimonialCarousel from './TestimonialCarousel';
import ThreeColImageBlocks from './ThreeColImageBlocks';
import ConfirmationCard from './ConfirmationCard';
import ContentSection from './ContentSection';
import AccordionSection from './AccordionSection';
import CompareTable from './CompareTableSection';
import StoreLocator from './StoreLocator';
import InsightCard from './InsightCard';
import TermsConditionsSection from './TermsConditionsSection';
import SoftSignUpConfirmationCard from './SoftSignUpConfirmationCard';
import Experiment from './Experiment';
import TwoColTextGrid from './TwoColTextGrid';
import PRCardSection from './PRCardSection';
import TwoRowTextButton from './TwoRowTextButton';
import RatingBanner from './RatingBanner';
import ThreeRowTextButton from './ThreeRowTextButton';
export { components } from './PortableTextComponents';

/**
 * This is a map of the Sanity page builder fields to React components.
 * This gets used on the (Global)/page.tsx and (Global)/[slug]/page.tsx routing pages.
 */

export const componentMap: any = {
  categoryBanner: CategoryBanner,
  homeBanner: HomeBanner,
  homeFeatureCards: FeatureProductCards,
  companyStatsHighlightPanel: CompanyStatsHighlightPanel,
  homeCarousel: HomeCarousel,
  featureList: FeatureList,
  featureCardGrid: FeatureCardGrid,
  twoColTextImage: TwoColTextImage,
  form: Form,
  informationCard: InformationCard,
  informationBanner: InformationBanner,
  productFeatureBanner: ProductFeatureBanner,
  appBanner: AppBanner,
  storeBanner: StoreBanner,
  uniqueSellingPointsBanner: UniqueSellingPointsBanner,
  compareProductsTable: CompareProductsTable,
  shopBySwiper: ShopBySwiper,
  productBanner: ProductBanner,
  productVideoAninmation: ProductVideoAninmation,
  threeColImageBlocks: ThreeColImageBlocks,
  twoColTextMediaCard: TwoColTextMediaCard,
  twoRowTextButton: TwoRowTextButton,
  ratingBanner: RatingBanner,
  trustSignalsStrip: TrustSignalsStrip,
  whatsInTheBox: WhatsInTheBox,
  priceCalculator: PricingCalculator,
  shopNavigationAnchorBanner: ShopNavigationAnchorBanner,
  productCardGrid: ProductCardGrid,
  heading: Heading,
  pageFAQ: FAQ,
  contactBlock: ContactBlock,
  productSpotlight: ProductSpotlight,
  pageBanner: PageBanner,
  testimonialCarousel: TestimonialCarousel,
  cardsSection: CardsSection,
  confirmationCard: ConfirmationCard,
  contentSection: ContentSection,
  accordionSection: AccordionSection,
  compareTable: CompareTable,
  storeLocator: StoreLocator,
  insightCard: InsightCard,
  termsConditionsSection: TermsConditionsSection,
  SSUConfirmationCard: SoftSignUpConfirmationCard,
  experiment: Experiment,
  twoColTextGrid: TwoColTextGrid,
  prCardSection: PRCardSection,
  threeRowTextButton: ThreeRowTextButton,
};
