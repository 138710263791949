'use client';
import React from 'react';
import Image from 'next/image';
import { Card, IKButton, IKLink } from '@/components/shared/material';
import { PortableTextBlock } from 'sanity';
import { MenuItemLink } from '@/types/types';
import { PortableText } from '@portabletext/react';
import { paragraphs } from '@/components/sections/PortableTextComponents';

export type CardType = {
  title?: PortableTextBlock;
  image?: {
    url?: string;
    alt?: string;
    blurDataURL?: string;
  };
  button: {
    text: string;
    link?: MenuItemLink;
  };
};

export default function FeatureCard(props: { card: CardType }) {
  const card = props.card;

  const hasLinkText = card.button.text;
  return (
    <>
      <div className='relative flex w-full flex-col'>
        <Card
          className={`relative z-10 flex max-h-24 flex-grow !flex-row items-center gap-5 rounded-2xl border border-yellow !bg-white px-5 py-4 shadow-lg shadow-black/10`}
        >
          {card.image && card.image.url && (
            <div className={hasLinkText ? '' : 'mx-auto'}>
              {card.button && card.button.link?.url ? (
                <IKLink link={card.button.link}>
                  <Image
                    width={150}
                    height={80}
                    src={card.image.url}
                    alt={card.image.alt ?? ''}
                    placeholder={'blur'}
                    blurDataURL={card.image.blurDataURL}
                    className={`w-full object-contain`}
                  />
                </IKLink>
              ) : (
                <>
                  <Image
                    width={150}
                    height={80}
                    src={card.image.url}
                    alt={card.image.alt ?? ''}
                    placeholder={'blur'}
                    blurDataURL={card.image.blurDataURL}
                    className={`w-full object-contain`}
                  />
                </>
              )}
            </div>
          )}
          {hasLinkText && (
            <div className='flex flex-shrink-0 flex-grow items-center justify-end p-0 sm:px-3 lg:px-5'>
              <IKButton
                link={card.button.link}
                color='charcoal'
                size='md'
                variant='text'
                className='bg-gradient-line group relative flex flex-shrink-0 items-center justify-center gap-1 overflow-x-hidden !rounded-none bg-right-bottom bg-no-repeat !px-0 !py-2 text-base transition-all duration-500 hover:!bg-left-bottom hover:transition-all hover:duration-500'
              >
                <span> {card.button.text}</span>
              </IKButton>
            </div>
          )}

          {!hasLinkText && card.title && (
            <div className='w-full flex-grow text-lg text-charcoal'>
              <PortableText components={paragraphs} value={card.title} />
            </div>
          )}
        </Card>
      </div>
    </>
  );
}
