'use client';
import React, { useCallback, useState } from 'react';
import Image from 'next/image';
import { Card, IKButton } from '@/components/shared/material';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from '@/components/sections';
import { MenuItemLink } from '@/types/types';
import { JobsModal } from '@/components/shared/modals/';

export type CardType = {
  title?: PortableTextBlock;
  description?: PortableTextBlock;
  image?: {
    url?: string;
    alt?: string;
    blurDataURL?: string;
  };
  button: {
    text: string;
    careerDepartmentId?: string;
    link?: MenuItemLink;
  };
};

export default function FeatureCard(props: { card: CardType }) {
  const card = props.card;

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <>
      <div className='relative flex w-full flex-col'>
        <Card
          className={`relative z-10 flex flex-grow flex-col gap-5 rounded-3xl border border-yellow !bg-white px-5 pb-5 pt-5 shadow-lg shadow-black/10 lg:pb-8`}
        >
          {card?.image && card?.image?.url && (
            <Image
              width={400}
              height={275}
              src={card?.image?.url}
              alt={card?.image?.alt ?? ''}
              placeholder={'blur'}
              blurDataURL={card?.image?.blurDataURL}
              className={'w-full object-contain'}
            />
          )}
          <div className='space-y-2 px-3 lg:px-5 lg:pt-5'>
            {card?.title && (
              <div className='heading-xs font-bold text-charcoal'>
                <PortableText components={components} value={card.title} />
              </div>
            )}
            {card?.description && (
              <div className='text-base text-charcoal xl:text-lg [&_strong]:text-teal'>
                <PortableText
                  components={components}
                  value={card.description}
                />
              </div>
            )}
          </div>
          <div className='mt-auto p-0 sm:px-3 lg:px-5'>
            {card?.button &&
              (card.button.link?.url || card.button.careerDepartmentId) && (
                <IKButton
                  link={card.button.link}
                  onClick={
                    card.button.careerDepartmentId ? openModal : undefined
                  }
                  color='light-blue'
                  size='sm'
                  className='group flex w-full items-center justify-center gap-1 text-base'
                >
                  <span> {card.button.text}</span>
                  <svg
                    className='h-6 w-6 transform transition-transform duration-300 group-hover:translate-x-2'
                    xmlns='http://www.w3.org/2000/svg'
                    width='25'
                    height='25'
                    viewBox='0 0 25 25'
                    fill='none'
                  >
                    <path
                      d='M17.5854 12.5703L10.0854 20.0704C10.0157 20.14 9.93302 20.1953 9.84197 20.233C9.75093 20.2707 9.65335 20.2901 9.5548 20.2901C9.45625 20.2901 9.35867 20.2707 9.26763 20.233C9.17658 20.1953 9.09386 20.14 9.02417 20.0704C8.95449 20.0007 8.89922 19.9179 8.8615 19.8269C8.82379 19.7359 8.80438 19.6383 8.80438 19.5397C8.80438 19.4412 8.82379 19.3436 8.8615 19.2526C8.89922 19.1615 8.95449 19.0788 9.02417 19.0091L15.9945 12.0397L9.02417 5.07035C8.88344 4.92962 8.80438 4.73875 8.80438 4.53972C8.80438 4.3407 8.88344 4.14983 9.02417 4.0091C9.1649 3.86837 9.35578 3.78931 9.5548 3.78931C9.75382 3.78931 9.94469 3.86837 10.0854 4.0091L17.5854 11.5091C17.6552 11.5788 17.7105 11.6615 17.7482 11.7525C17.786 11.8436 17.8054 11.9412 17.8054 12.0397C17.8054 12.1383 17.786 12.2359 17.7482 12.3269C17.7105 12.418 17.6552 12.5007 17.5854 12.5703Z'
                      fill='currentColor'
                    />
                  </svg>
                </IKButton>
              )}
          </div>
        </Card>
      </div>
      {card?.button?.careerDepartmentId && (
        <JobsModal
          open={isModalOpen}
          handler={setModalOpen}
          title={card.title}
          departmentId={card.button.careerDepartmentId}
        />
      )}
    </>
  );
}
