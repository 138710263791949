'use client';
import React, { useCallback, useRef } from 'react';
import { m, useInView } from 'framer-motion';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import {
  components,
  paragraphs,
} from '@/components/sections/PortableTextComponents';
import { IKButton } from '@/components/shared/material';
import { fadeUp } from '@/assets/framer/animations';
import { confirmationRedirectURLAtom } from '@/store/formStore';
import { useAtom } from 'jotai';
import { useRouter } from 'next/navigation';
import { getHomePageUrl } from '@/lib/pathUtils';
import { mixpanelBtnClick } from '@/lib/mixpanel/services/client';
import { portableTextToString } from '../utils/utils';

export default function ContactBlock(props: {
  data?: {
    sectionId?: string;
    heading?: PortableTextBlock;
    textContent?: PortableTextBlock;
    subText?: PortableTextBlock;
    backButtonText?: string;
  };
}) {
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const textContent = props.data?.textContent;
  const subText = props.data?.subText;
  const backButtonText = props.data?.backButtonText;
  const section = useRef(null);
  const isInView = useInView(section, { amount: 0.3, once: true });
  const [confimrationRedirectURL, setConfirmationRedirectURL] = useAtom(
    confirmationRedirectURLAtom
  );
  const router = useRouter();

  const handleClick = useCallback(() => {
    if (confimrationRedirectURL) {
      router.push(confimrationRedirectURL);
      // Set to null to prevent redirecting to the same page
      const btn = document.createElement('a');
      btn.href = confimrationRedirectURL;
      btn.innerText = backButtonText ?? 'Back';

      mixpanelBtnClick(btn, portableTextToString(heading));
      setConfirmationRedirectURL(null);
    } else {
      const homeURL = getHomePageUrl() !== '' ? getHomePageUrl() : '/';
      const btn = document.createElement('a');
      btn.href = homeURL;
      btn.innerText = backButtonText ?? 'Back';
      mixpanelBtnClick(btn, portableTextToString(heading));
      router.push(homeURL); // If no redirect URL is set, redirect to the home page
    } // If no redirect URL is set, redirect to the home page
  }, [confimrationRedirectURL, router, setConfirmationRedirectURL]);

  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className='section-padding bg-gradient-yellow flex min-h-[max(90vh,600px)] scroll-m-16 items-center bg-gradient-to-b'
      >
        <div className='container-padding container pt-10'>
          {/* Card */}
          <div className='mx-auto w-full max-w-[420px] space-y-6 rounded-3xl border bg-white px-8 py-20 text-charcoal'>
            {/* Icon */}
            <div className='flex justify-center'>
              <div className='flex h-16 w-16 transform items-center justify-center rounded-full bg-yellow'>
                <m.svg
                  initial='hidden'
                  variants={fadeUp}
                  animate={isInView ? 'visible' : 'hidden'}
                  className='h-8 w-8 fill-charcoal text-charcoal'
                  xmlns='http://www.w3.org/2000/svg'
                  width='33'
                  height='26'
                  viewBox='0 0 33 26'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10.7576 25.8574C9.97778 25.8574 9.1967 25.5533 8.60221 24.9443L1.02983 17.2037C-0.160985 15.987 -0.160985 14.0136 1.02983 12.797C2.21943 11.5797 4.15107 11.5797 5.34067 12.797L10.7576 18.3337L27.6601 1.05603C28.8497 -0.161254 30.7813 -0.161254 31.9709 1.05603C33.1617 2.27269 33.1617 4.24604 31.9709 5.46271L12.913 24.9443C12.3186 25.5533 11.5375 25.8574 10.7576 25.8574Z'
                    fill='#1D1D1B'
                  />
                </m.svg>
              </div>
            </div>

            {/* Body */}
            {heading && (
              <m.h3
                initial='hidden'
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
                transition={{ delay: 0.1 }}
                className='text-center text-2xl font-bold'
              >
                <PortableText value={heading} components={components} />
              </m.h3>
            )}

            {textContent && (
              <m.div
                initial='hidden'
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
                transition={{ delay: 0.2 }}
                className='mx-auto max-w-[360px] text-center'
              >
                <PortableText value={textContent} />
              </m.div>
            )}

            {backButtonText && (
              <m.div
                initial='hidden'
                variants={fadeUp}
                animate={isInView ? 'visible' : 'hidden'}
                transition={{ delay: 0.2 }}
                className='flex w-full items-center justify-center'
              >
                <IKButton
                  onClick={() => handleClick()}
                  color='yellow'
                  size='sm'
                  className='flex w-full items-center justify-center gap-2 px-5'
                >
                  <span>{backButtonText}</span>
                </IKButton>
              </m.div>
            )}
          </div>

          {subText && (
            <div
              className={`mt-10 text-center font-normal text-charcoal md:mt-16 [&_a]:underline`}
            >
              <PortableText value={subText} components={paragraphs} />
            </div>
          )}
        </div>
      </section>
    </>
  );
}
