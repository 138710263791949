'use client';
import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useMatchMedia } from '@/lib/matchMedia';
import MuxPlayer from '@mux/mux-player-react';
import { m, useInView } from 'framer-motion';

export default function MuxVideoPlayer({
  muxVideoId,
  className,
  image,
}: {
  muxVideoId: string;
  image?: {
    url: string;
    alt: string;
    blurDataUrl: string;
  };
  className?: string;
}) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const isDesktopResolution = useMatchMedia('(min-width:1080px)', true);

  const video = useRef<null | HTMLVideoElement>(null);
  const isVideoInView = useInView(video, { amount: 0.2, once: false });

  useEffect(() => {
    if (isVideoInView && video.current) {
      video.current.play();
      setIsVideoPlaying(true);
    } else if (!isVideoInView && video.current) {
      video.current.pause();
    }
  }, [isVideoInView, video]);

  return (
    <>
      {muxVideoId && (
        <div
          onMouseOver={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          className={`full relative h-full cursor-pointer lg:min-w-[420px] ${className}`}
        >
          {
            <>
              {muxVideoId && (
                <MuxPlayer
                  // @ts-expect-error
                  ref={video}
                  className={`pointer-events-none after:!bg-none`}
                  muted={true}
                  nohotkeys
                  nospace
                  controls={false}
                  autoPlay={true}
                  loop={true}
                  style={{
                    /* Target all sections by excluding the section prefix */
                    // @ts-expect-error
                    '--play-button': 'none',
                    '--live-button': 'none',
                    '--seek-backward-button': 'none',
                    '--seek-forward-button': 'none',
                    '--mute-button': 'none',
                    '--captions-button': 'none',
                    '--airplay-button': 'none',
                    '--pip-button': 'none',
                    '--fullscreen-button': 'none',
                    '--cast-button': 'none',
                    '--playback-rate-button': 'none',
                    '--volume-range': 'none',
                    '--time-range': 'none',
                    '--time-display': 'none',
                    '--duration-display': 'none',
                    '--rendition-selectmenu': 'none',
                    '--center-controls': 'none',
                    '--controls': 'none',
                    '--media-background-color': 'transparent',
                    aspectRatio: '1/1',
                    height: '100%',
                    width: '100%',
                    left: 0,
                    backgroundColor: 'transparent',
                    top: 0,
                  }}
                  streamType='on-demand'
                  playbackId={muxVideoId}
                  metadataVideoTitle='Video'
                  primaryColor='#FFFFFF'
                />
              )}
            </>
          }

          {image && image.url && (
            <>
              <m.div
                animate={
                  isVideoPlaying || (isMouseOver && isDesktopResolution)
                    ? { opacity: 0 }
                    : { opacity: 1 }
                }
                className='absolute left-0 top-0 z-[1] h-full w-full overflow-hidden'
              >
                <Image
                  placeholder='blur'
                  blurDataURL={image ? image.blurDataUrl : ''}
                  className='h-full w-full object-cover'
                  width={500}
                  height={500}
                  src={image ? image.url : ''}
                  alt={image ? image.alt : ''}
                />
              </m.div>
            </>
          )}
        </div>
      )}
    </>
  );
}
