'use client';
import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { IKButton } from '@/components/shared/material';
import { PortableText } from '@portabletext/react';
import { PortableTextBlock } from 'sanity';
import { components } from './PortableTextComponents';
import { m, useInView, AnimatePresence } from 'framer-motion';
import { fadeUp } from '@/assets/framer/animations';
import { MenuItemLink } from '@/types/types';
import YouTube, { YouTubeProps } from 'react-youtube';
import { useMatchMedia } from '@/lib/matchMedia';

export default function AppBanner(props: {
  data?: {
    _key?: string;
    sectionId?: string;
    heading?: PortableTextBlock;
    subheading?: PortableTextBlock;
    disclaimerText?: PortableTextBlock;
    buttons?: [
      {
        text?: string;
        link: MenuItemLink;
      },
    ];
    image?: {
      url?: string;
      alt?: string;
      placeholderUrl?: string;
    };
    mobileImage?: {
      url?: string;
      alt?: string;
      placeholderUrl?: string;
    };
    type: 'video' | 'image';
    video?: string;
    featureImage1?: {
      url?: string;
      alt?: string;
      placeholderUrl?: string;
    };
    featureImage2?: {
      url?: string;
      alt?: string;
      placeholderUrl?: string;
    };
    items?: [
      {
        text: string;
        image?: {
          url: string;
          alt?: string;
          placeholderUrl?: string;
        };
      },
    ];
    displayOptions: {
      patternColor: {
        value: string;
        label: string;
      };
    };
  };
}) {
  // Props
  const sectionKey = props.data?._key;
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const buttons = props.data?.buttons;
  const subheading = props.data?.subheading;
  const disclaimerText = props.data?.disclaimerText;
  const image = props.data?.image;
  const mobileImage = props.data?.mobileImage;
  const isDesktopResolution = useMatchMedia('(min-width:768px)', true);
  const desktopImageIsValid = isDesktopResolution && image && image.url;
  const mobileImageIsValid =
    !isDesktopResolution && mobileImage && mobileImage.url;
  const mainImage = desktopImageIsValid
    ? image
    : mobileImageIsValid
      ? mobileImage
      : image;
  const displayOptions = props.data?.displayOptions;

  const patternColor = displayOptions?.patternColor || {
    value: '#FFD700',
  };
  const featureImage1 = props.data?.featureImage1;
  const featureImage2 = props.data?.featureImage2;
  const items = props.data?.items;
  const video = props.data?.video;
  const headingRef = useRef(null);
  const section = useRef(null);
  const isInView = useInView(headingRef, { amount: 0.4, once: true });

  // Dynamic
  const hasFeatureImages =
    featureImage1 &&
    featureImage2 &&
    featureImage1.url !== null &&
    featureImage2.url !== null;

  const [activeVideo, setActiveVideo] = useState<string | null>(null);

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '640',
    iframeClassName: 'w-full h-full',
  };

  const setActiveVideoHandler = () => {
    if (video) {
      setActiveVideo(video);
    }
  };

  useEffect(() => {
    if (activeVideo) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [activeVideo]);

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.playVideo();
  };

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (
        (event.key === 'Escape' && activeVideo) ||
        (event.key === 'Esc' && activeVideo)
      ) {
        setActiveVideo(null);
      }
    };

    window.addEventListener('keydown', handleEscKeyPress);

    return () => {
      window.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [activeVideo]);

  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className='section-padding relative scroll-m-16 pb-16'
      >
        <div className='container-padding container relative mx-auto'>
          <div className='grid gap-10 pt-10 sm:pt-0 md:grid-cols-4'>
            <div className='relative order-2 md:order-1 md:col-span-2'>
              <m.h2
                initial='hidden'
                animate={isInView ? 'visible' : 'hidden'}
                variants={fadeUp}
                ref={headingRef}
                className='heading-xl relative z-10 pb-4 font-display font-medium tracking-tighter text-charcoal sm:max-w-[700px] [&_strong]:text-pink'
              >
                {heading && (
                  <PortableText
                    components={components}
                    value={heading}
                  ></PortableText>
                )}
              </m.h2>
              <p className='mb-3 mt-0 border-b border-gray-300 pb-3 text-xl leading-8 md:mb-5 md:pb-5'>
                {subheading && (
                  <PortableText
                    components={components}
                    value={subheading}
                  ></PortableText>
                )}
              </p>
              {/* Product Features */}
              {items && items.length > 0 && (
                <>
                  <h3 className='sr-only'>Product Features</h3>
                  <ul className='mb-5 md:mb-0'>
                    {items.map((item, index) => (
                      <li
                        key={sectionKey + '-item-' + index}
                        className='flex items-center gap-4 py-2'
                      >
                        {item.image && item.image?.url ? (
                          <Image
                            src={item.image?.url}
                            alt={item.image?.alt || ''}
                            width={24}
                            height={24}
                            className='h-8 w-8'
                          />
                        ) : (
                          <div className='flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-yellow-200 bg-gradient-to-r !from-yellow-400 !to-yellow-300 font-bold'>
                            {index + 1}
                          </div>
                        )}

                        <span className=''>{item.text}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {/* Action buttons */}
              <div className='flex flex-wrap items-stretch justify-center gap-5 sm:items-center sm:gap-10 md:pt-6 xxs:justify-start'>
                {buttons &&
                  buttons.length > 0 &&
                  buttons.map((button, index) => (
                    <IKButton
                      key={`${sectionKey}-store-button-${index}`}
                      link={button.link}
                      color={index === 0 ? 'yellow' : 'gray'}
                      // @ts-ignore
                      variant={index === 0 ? 'filled' : 'text'}
                      size={index === 0 ? 'md' : 'sm'}
                      className={`flex items-center justify-center gap-1 sm:justify-start ${
                        index === 0
                          ? 'w-full px-8 sm:w-auto'
                          : 'bg-gradient-line relative overflow-x-hidden !rounded-none bg-right-bottom bg-no-repeat !py-2 px-2 transition-all duration-500 hover:!bg-left-bottom hover:transition-all hover:duration-500'
                      }`}
                    >
                      <span>{button.text}</span>
                      {index === 0 && (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='25'
                          height='25'
                          viewBox='0 0 25 25'
                          fill='none'
                        >
                          <path
                            d='M17.5854 12.5703L10.0854 20.0704C10.0157 20.14 9.93302 20.1953 9.84197 20.233C9.75093 20.2707 9.65335 20.2901 9.5548 20.2901C9.45625 20.2901 9.35867 20.2707 9.26763 20.233C9.17658 20.1953 9.09386 20.14 9.02417 20.0704C8.95449 20.0007 8.89922 19.9179 8.8615 19.8269C8.82379 19.7359 8.80438 19.6383 8.80438 19.5397C8.80438 19.4412 8.82379 19.3436 8.8615 19.2526C8.89922 19.1615 8.95449 19.0788 9.02417 19.0091L15.9945 12.0397L9.02417 5.07035C8.88344 4.92962 8.80438 4.73875 8.80438 4.53972C8.80438 4.3407 8.88344 4.14983 9.02417 4.0091C9.1649 3.86837 9.35578 3.78931 9.5548 3.78931C9.75382 3.78931 9.94469 3.86837 10.0854 4.0091L17.5854 11.5091C17.6552 11.5788 17.7105 11.6615 17.7482 11.7525C17.786 11.8436 17.8054 11.9412 17.8054 12.0397C17.8054 12.1383 17.786 12.2359 17.7482 12.3269C17.7105 12.418 17.6552 12.5007 17.5854 12.5703Z'
                            fill='currentColor'
                          />
                        </svg>
                      )}
                    </IKButton>
                  ))}
              </div>
              {disclaimerText && (
                <p className='relative bottom-0 left-0 mt-10 block w-full pb-2 text-xs lg:pb-0'>
                  <PortableText
                    components={components}
                    value={disclaimerText}
                  />
                </p>
              )}
            </div>
            <div className='relative order-1 flex items-center justify-center md:col-span-2 xl:order-2'>
              {/* App of the Year */}
              {featureImage1 && featureImage1.url !== null && (
                <Image
                  src={featureImage1.url || ''}
                  alt={featureImage1.alt || ''}
                  height={80}
                  width={80}
                  placeholder='blur'
                  blurDataURL={featureImage1.placeholderUrl || ''}
                  className='absolute bottom-20 left-12 h-12 w-12 md:bottom-36 md:left-6 xl:bottom-36 xl:left-28 xl:h-20 xl:w-20'
                />
              )}
              {featureImage2 && featureImage2.url !== null && (
                <Image
                  src={featureImage2.url || ''}
                  alt={featureImage2.alt || ''}
                  height={80}
                  width={80}
                  placeholder='blur'
                  blurDataURL={featureImage2.placeholderUrl || ''}
                  className='absolute bottom-6 left-12 h-12 w-12 md:bottom-24 md:left-6 xl:bottom-12 xl:left-28 xl:h-20 xl:w-20'
                />
              )}

              {!hasFeatureImages && (
                <>
                  {/* Large pattern */}
                  <svg
                    width='70'
                    height='70'
                    viewBox='0 0 70 70'
                    fill='none'
                    className='absolute -top-10 left-12 md:left-0 md:top-20 lg:top-0 xl:left-20 xl:top-0'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M27.7835 28.1507C28.0851 28.1513 28.3269 27.9103 28.3274 27.6084L28.3523 14.8277L41.5641 14.8535L41.5392 27.6341C41.5386 27.936 41.7795 28.178 42.0811 28.1786L54.388 28.2026L54.3623 41.3656L41.593 41.3407C41.2913 41.3402 41.0495 41.5812 41.0489 41.8831L41.0234 54.9858L28.5959 54.9616L28.6213 41.9193C28.6219 41.6174 28.3811 41.3754 28.0794 41.3748L14.9682 41.3492L14.994 28.1258L27.7633 28.1507L27.7835 28.1507ZM0.789664 42.1066L13.9009 42.1321L13.8747 55.5769C13.8741 55.8788 14.115 56.1208 14.4166 56.1214L27.749 56.1474L27.7236 69.1897C27.723 69.4916 27.9638 69.7336 28.2655 69.7342L41.9397 69.7608C42.2414 69.7614 42.4832 69.5204 42.4838 69.2185L42.5094 56.0957L55.2787 56.1205C55.5804 56.1211 55.8221 55.8801 55.8227 55.5782L55.8489 42.1333L68.6183 42.1582C68.9199 42.1588 69.1617 41.9178 69.1623 41.6159L69.1889 27.9496C69.1895 27.6477 68.9487 27.4057 68.647 27.4051L56.3402 27.3812L56.3663 13.9967C56.3669 13.6948 56.126 13.4528 55.8244 13.4522L42.3914 13.4261L42.4163 0.625289C42.4169 0.323384 42.1761 0.0813902 41.8745 0.0808024L28.2203 0.0541948C27.9186 0.053607 27.6769 0.294661 27.6763 0.596566L27.6514 13.3571L14.2184 13.3309C13.9168 13.3303 13.675 13.5714 13.6744 13.8733L13.6482 27.3181L0.898985 27.2933C0.597346 27.2927 0.355525 27.5337 0.354937 27.8356L0.328306 41.5019C0.327718 41.8038 0.568597 42.0457 0.870236 42.0463'
                      fill={patternColor?.value}
                    />
                  </svg>
                  {/* Small pattern */}
                  <svg
                    width='43'
                    height='43'
                    viewBox='0 0 43 43'
                    fill='none'
                    className='absolute left-0 top-4 md:-left-12 md:top-40 lg:top-20 xl:left-12'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M27.5986 27.521L14.789 27.496L14.8124 15.4802L27.622 15.5052L27.5986 27.521ZM0.568131 29.3401L13.8804 29.3661L13.8564 41.724C13.8558 42.0259 14.0966 42.2679 14.3983 42.2685L28.0725 42.2952C28.3742 42.2957 28.616 42.0547 28.6166 41.7528L28.6426 28.4086L41.8141 28.4343C42.1158 28.4348 42.3575 28.1938 42.3581 27.8919L42.3848 14.2055C42.3854 13.9036 42.1445 13.6616 41.8429 13.6611L28.5306 13.6351L28.5547 1.27714C28.5553 0.975235 28.3144 0.73324 28.0128 0.732652L14.3385 0.706006C14.0369 0.705418 13.7951 0.946473 13.7945 1.24838L13.7685 14.5926L0.59692 14.5669C0.295281 14.5663 0.0535216 14.8074 0.0529333 15.1093L0.0263022 28.7755C0.0257139 29.0774 0.266532 29.3194 0.568171 29.32'
                      fill={patternColor?.value}
                    />
                  </svg>
                </>
              )}

              <div
                onClick={() => setActiveVideoHandler()}
                aria-label={`${video ? 'Watch video' : ''}`}
                title={`${video ? 'Watch video' : ''}`}
                className={`aspect-square w-full sm:w-3/4 md:w-full xl:w-3/4 ${video ? 'cursor-pointer' : ''}`}
              >
                {mainImage && (
                  <Image
                    src={mainImage.url || ''}
                    alt={mainImage.alt || ''}
                    height={600}
                    width={600}
                    placeholder='blur'
                    blurDataURL={mainImage.placeholderUrl || ''}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Video Modal */}
      <AnimatePresence>
        {activeVideo && (
          <m.div
            initial={false}
            className={`fixed left-0 top-0 z-[99999] h-screen w-screen bg-black/90`}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className='container-padding container relative flex h-full items-center justify-center'>
              <button
                className='absolute right-5 top-10 z-[100] rounded-full bg-white/10 px-4 py-4 text-white/90 transition duration-200 hover:bg-white/20 md:right-0'
                onClick={() => setActiveVideo(null)}
              >
                <svg
                  className='h-4 w-4 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  width='23'
                  height='23'
                  viewBox='0 0 23 23'
                  fill='none'
                >
                  <path
                    d='M21.5362 1.30971C21.8319 1.60542 21.9981 2.00649 21.9981 2.42469C21.9981 2.84289 21.8319 3.24396 21.5362 3.53967L3.69651 21.3794C3.4008 21.6751 2.99973 21.8412 2.58153 21.8412C2.16333 21.8412 1.76226 21.6751 1.46654 21.3794C1.17083 21.0837 1.0047 20.6826 1.0047 20.2644C1.0047 19.8462 1.17083 19.4451 1.46654 19.1494L19.3063 1.30971C19.602 1.014 20.003 0.847866 20.4212 0.847866C20.8394 0.847866 21.2405 1.014 21.5362 1.30971Z'
                    fill='currentColor'
                  />
                  <path
                    d='M21.5367 21.3807C21.241 21.6764 20.8399 21.8425 20.4217 21.8425C20.0035 21.8425 19.6025 21.6764 19.3068 21.3807L1.46705 3.54099C1.17134 3.24528 1.00521 2.84421 1.00521 2.42601C1.00521 2.00781 1.17134 1.60674 1.46705 1.31103C1.76276 1.01532 2.16383 0.849186 2.58203 0.849186C3.00023 0.849186 3.4013 1.01532 3.69701 1.31103L21.5367 19.1507C21.8324 19.4465 21.9986 19.8475 21.9986 20.2657C21.9986 20.6839 21.8324 21.085 21.5367 21.3807Z'
                    fill='currentColor'
                  />
                </svg>
              </button>
              {/* @ts-ignore  */}
              <YouTube
                videoId={activeVideo}
                opts={opts}
                className='aspect-video w-full max-w-4xl overflow-hidden rounded-md bg-black [aspect-ratio:_16_/_9]'
                iframeClassName='aspect-video h-full w-full [aspect-ratio:16_/_9]'
                onReady={onPlayerReady}
              />
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </>
  );
}
