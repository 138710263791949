'use client';
import { useRef } from 'react';
import { m, useInView } from 'framer-motion';
import { PortableTextBlock } from 'sanity';
import { fadeUp } from '@/assets/framer/animations';
import { components, noParagraphs } from '../PortableTextComponents';
import { PortableText } from '@portabletext/react';
import { AcceptedPaymentMethods } from '@/components/shared/groupIcons';
import { useMatchMedia } from '@/lib/matchMedia';
import Image from 'next/image';

export default function ProductSpotlight(props: {
  data: {
    sectionId?: string;
    heading?: PortableTextBlock;
    subheading?: PortableTextBlock;
    image?: {
      url?: string;
      alt?: string;
      blurDataUrl?: string;
    };
    mobileImage?: {
      url?: string;
      alt?: string;
      blurDataUrl?: string;
    };
    acceptedPaymentMethodsText: PortableTextBlock;
    hideAcceptedPaymentMethods: boolean;
  };
}) {
  const section = useRef(null);
  const isInView = useInView(section, { amount: 0.4, once: true });
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const subheading = props.data?.subheading;
  const image = props.data?.image;
  const acceptedPaymentMethodsText = props.data?.acceptedPaymentMethodsText;
  const mobileImage = props.data?.mobileImage;
  const isDesktopResolution = useMatchMedia('(min-width: 768px)', true);
  const hideAcceptedPaymentMethods = props.data?.hideAcceptedPaymentMethods;
  return (
    <>
      <section
        id={sectionId}
        ref={section}
        className={`relative z-40 ${subheading ? '-mt-24' : '-mt-20 mb-20'} pointer-events-none select-none scroll-m-16 bg-gradient-to-b pt-5 sm:pt-0 md:-mt-10`}
      >
        <div
          className={`${subheading ? 'top-24 flex-col justify-start gap-3.5 pb-32 sm:gap-5 md:top-0 md:mt-0 md:items-start md:justify-center' : 'justify-center md:items-center md:justify-start'} container-padding container absolute left-[-1px] right-0 z-10 mx-auto flex h-full w-full items-end`}
        >
          {heading && (
            <m.h2
              initial='hidden'
              animate={isInView ? 'visible' : 'hidden'}
              variants={fadeUp}
              className={`${subheading ? 'text-[38px] sm:text-5xl xl:text-7xl' : 'w-max pb-32 text-center text-5xl lg:text-6xl 2xl:text-[73px]'} xl:max-[650px] relative z-10 mx-auto max-w-[450px] font-display font-bold leading-[1] tracking-tighter text-yellow-150 sm:max-w-[550px] sm:text-left md:mx-[unset] md:w-full md:max-w-[450px] md:pb-0 md:leading-[0.95] xl:max-w-[650px] [&_br]:hidden md:[&_br]:inline [&_strong]:text-yellow`}
            >
              {heading && (
                <PortableText
                  components={components}
                  value={heading}
                ></PortableText>
              )}
            </m.h2>
          )}

          {/* subheading */}
          {subheading && (
            <m.p
              initial='hidden'
              animate={isInView ? 'visible' : 'hidden'}
              variants={fadeUp}
              className='relative z-10 mx-auto -mb-32 w-full max-w-[450px] font-sans text-base leading-7 tracking-tight text-gray-300 sm:mb-0 sm:max-w-[550px] sm:text-left sm:text-xl md:mx-[unset] md:max-w-[450px] lg:max-w-[530px] lg:text-2xl 2xl:text-[24px] [&_br]:hidden md:[&_br]:inline'
            >
              <PortableText
                components={components}
                value={subheading}
              ></PortableText>
            </m.p>
          )}
        </div>

        <div className='relative z-[5] mx-auto flex h-[80vh] w-full justify-center overflow-hidden pt-10 md:pt-0'>
          <div className='relative h-full w-full'>
            {image && image.url && (
              <>
                <svg
                  className='absolute'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 1471 633'
                  preserveAspectRatio='none'
                >
                  <defs>
                    <clipPath
                      id='curved-top-bottom'
                      clipPathUnits='objectBoundingBox'
                      className='w-[200%]'
                    >
                      <path
                        className='hidden md:block'
                        d='M 1 0.0221 C 0.9993 0.0223 0.9972 0.0228 0.9948 0.0231 C 0.9556 0.0300 0.7837 0.0606 0.5007 0.0203 C 0.2182 -0.0200 0.0479 0.0116 0.0086 0.0188 L 0.0086 0.0188 C 0.0039 0.0197 0.0011 0.0203 0.0002 0.0203 L 0.0002 0.2524 L 0 0.2645 V 0.9801 C 0.0436 0.9729 0.2076 0.9504 0.5007 0.9821 C 0.7891 1.0134 0.9554 0.9931 1 0.9852 V 0.0221 Z'
                      ></path>
                      <path
                        className='block md:hidden'
                        d='M0 0.0365 C 0.0129 0.0365 0.4227 -0.0476 1 0.0395 V 1 C 0.6541 0.9485 0.3443 0.9485 0 1 L 0 0.0365 Z'
                      />
                    </clipPath>
                  </defs>
                </svg>
                {isDesktopResolution ? (
                  <Image
                    className='hidden h-full w-[102%] bg-charcoal object-cover object-center [clip-path:_url(#curved-top-bottom)] md:block'
                    height={1080}
                    width={1920}
                    src={image.url}
                    alt={image.alt ?? ''}
                    placeholder='blur'
                    quality={95}
                    blurDataURL={image.blurDataUrl ?? ''}
                  />
                ) : (
                  <>
                    {mobileImage && mobileImage.url ? (
                      <>
                        <Image
                          className='block h-full w-[102%] bg-charcoal object-cover object-center [clip-path:_url(#curved-top-bottom)] md:hidden'
                          height={1080}
                          width={900}
                          src={mobileImage.url}
                          alt={mobileImage.alt ?? ''}
                          placeholder='blur'
                          blurDataURL={mobileImage.blurDataUrl ?? ''}
                        />
                      </>
                    ) : (
                      <>
                        {image && image.url && (
                          <Image
                            className='block h-full w-[102%] bg-charcoal object-cover object-center [clip-path:_url(#curved-top-bottom)] md:hidden'
                            height={1080}
                            width={900}
                            src={image.url}
                            alt={image.alt ?? ''}
                            placeholder='blur'
                            blurDataURL={image.blurDataUrl ?? ''}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <div className='absolute bottom-0 left-0 z-[2] h-[55%] w-full bg-gradient-to-t from-charcoal via-charcoal/80 to-charcoal/[0] [clip-path:_url(#curved-top-bottom)] md:hidden'></div>
          </div>
        </div>

        {!hideAcceptedPaymentMethods ? (
          <div className='container-padding mb absolute z-10 w-full -translate-y-[40%] transform items-center justify-center pb-28 md:bottom-0 md:z-20 md:mb-0 md:flex md:translate-y-[50%] md:pb-0'>
            <div className='bg-gradient-charcoal flex flex-col items-center justify-center gap-2 rounded-3xl bg-charcoal-900 px-5 py-7 md:min-w-[530px] md:px-10'>
              {acceptedPaymentMethodsText && (
                <h4 className='text-gray-300'>
                  <PortableText
                    components={noParagraphs}
                    value={acceptedPaymentMethodsText}
                  ></PortableText>
                </h4>
              )}

              <AcceptedPaymentMethods
                color='white'
                className='w-full opacity-80'
              />
            </div>
          </div>
        ) : null}
      </section>
    </>
  );
}
