'use client';
import React, { useRef } from 'react';
import { PortableTextBlock } from 'sanity';
import { UniqueSellingPointsCard } from '@/components/shared/cards';
import { m, useInView } from 'framer-motion';
import Pattern from '@/public/images/Pattern.png';
import Image from 'next/image';
import { DisplayOptionsType, MenuItemLink } from '@/types/types';
import { fadeUp } from '@/assets/framer/animations';
import { PortableText } from '@portabletext/react';
import { noParagraphs } from './PortableTextComponents';
import { backgroundClasses } from '../utils/utils';

const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      type: 'spring',
    },
  },
};

const cardAnimation = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0 },
};

export type CardType = {
  title?: PortableTextBlock;
  description?: PortableTextBlock;
  image?: {
    url?: string;
    alt?: string;
    placeholderUrl?: string;
  };
  button: {
    text: string;
    link?: MenuItemLink;
  };
};

export default function UniqueSellingPointsBanner(props: {
  data?: {
    _key?: string;
    sectionId?: string;
    heading?: PortableTextBlock;
    cards: CardType[];
    displayOptions: {
      padding: 'py' | 'pt' | 'pb' | 'p-0';
      showPattern: boolean;
      showTopDividerWave: boolean;
      background: DisplayOptionsType['background'];
      customBackground: DisplayOptionsType['customBackground'];
    };
  };
}) {
  const cardContainerRef = useRef(null);
  const section = useRef(null);

  const isInView = useInView(cardContainerRef, { amount: 0.3, once: true });
  const sectionKey = props.data?._key;
  const sectionId = props.data?.sectionId;
  const heading = props.data?.heading;
  const cards = props.data?.cards;
  const displayOptions = props.data?.displayOptions;
  const showTopDividerWave = displayOptions?.showTopDividerWave ?? false;
  const backgroundClass = backgroundClasses(displayOptions);

  return (
    <section
      id={sectionId}
      ref={section}
      className={`scroll-m-16 ${
        displayOptions?.padding === 'py'
          ? 'section-padding-t section-padding-b-lg'
          : displayOptions?.padding === 'pb'
            ? 'section-padding-b-lg -top-10 -mb-10 lg:-top-20 lg:-mb-20' // -top-10 to offset the grid for cash advance below the What's an iK Cash Advance
            : displayOptions?.padding === 'pt'
              ? 'section-padding-t'
              : 'section-padding'
      } relative ${backgroundClass} relative`}
    >
      {/* Top Divider wave */}
      {showTopDividerWave && (
        <div className='absolute left-0 top-0 z-20 h-max w-full -translate-y-[calc(100%_-_2px)] transform overflow-hidden'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='w-[200%] fill-white md:w-full'
            width='1464'
            height='34'
            viewBox='0 0 1464 34'
            fill='none'
            preserveAspectRatio='none'
          >
            <path
              className='fill-white'
              d='M0 34H1464V13.9567C1462.14 14.0919 1459.76 14.2755 1456.86 14.4993L1456.79 14.5044C1400.39 18.8525 1148.67 38.2577 731.531 12.6924C315.789 -12.7874 65.0374 7.19974 7.26685 11.8046C4.35205 12.0369 1.92871 12.23 0 12.3763V34Z'
              fill='none'
            />
          </svg>
        </div>
      )}
      <div
        className={` ${showTopDividerWave ? 'pt-14' : ''} container-padding z-40z container relative z-40 mx-auto`}
      >
        <div>
          {heading && (
            <m.h2
              variants={fadeUp}
              animate={isInView ? 'visible' : 'hidden'}
              className='heading-xl relative z-10 max-w-xl font-display font-bold tracking-tighter text-charcoal sm:max-w-4xl [&_strong]:text-pink'
            >
              <PortableText value={heading} components={noParagraphs} />
            </m.h2>
          )}

          {heading && (
            <div className='absolute right-20 top-0 hidden lg:block'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='114'
                height='108'
                viewBox='0 0 114 108'
                fill='none'
              >
                <path
                  d='M71.7835 28.1507C72.0851 28.1513 72.3269 27.9103 72.3274 27.6084L72.3523 14.8277L85.5641 14.8535L85.5392 27.6341C85.5386 27.936 85.7795 28.178 86.0811 28.1786L98.388 28.2026L98.3623 41.3656L85.593 41.3407C85.2913 41.3402 85.0495 41.5812 85.0489 41.8831L85.0234 54.9858L72.5959 54.9616L72.6213 41.9193C72.6219 41.6174 72.3811 41.3754 72.0794 41.3748L58.9682 41.3492L58.994 28.1258L71.7633 28.1507L71.7835 28.1507ZM44.7897 42.1066L57.9009 42.1321L57.8747 55.5769C57.8741 55.8788 58.115 56.1208 58.4166 56.1214L71.749 56.1474L71.7236 69.1897C71.723 69.4916 71.9638 69.7336 72.2655 69.7342L85.9397 69.7608C86.2414 69.7614 86.4832 69.5204 86.4838 69.2185L86.5094 56.0957L99.2787 56.1205C99.5804 56.1211 99.8221 55.8801 99.8227 55.5782L99.8489 42.1333L112.618 42.1582C112.92 42.1588 113.162 41.9178 113.162 41.6159L113.189 27.9496C113.19 27.6477 112.949 27.4057 112.647 27.4051L100.34 27.3812L100.366 13.9967C100.367 13.6948 100.126 13.4528 99.8244 13.4522L86.3914 13.4261L86.4163 0.625289C86.4169 0.323384 86.1761 0.0813902 85.8745 0.0808024L72.2203 0.0541948C71.9186 0.053607 71.6769 0.294661 71.6763 0.596566L71.6514 13.3571L58.2184 13.3309C57.9168 13.3303 57.675 13.5714 57.6744 13.8733L57.6482 27.3181L44.899 27.2933C44.5973 27.2927 44.3555 27.5337 44.3549 27.8356L44.3283 41.5019C44.3277 41.8038 44.5686 42.0457 44.8702 42.0463'
                  fill='#FFCD00'
                />
                <path
                  d='M27.5986 92.521L14.789 92.496L14.8124 80.4802L27.622 80.5052L27.5986 92.521ZM0.568131 94.3401L13.8804 94.3661L13.8564 106.724C13.8558 107.026 14.0966 107.268 14.3983 107.269L28.0725 107.295C28.3742 107.296 28.616 107.055 28.6166 106.753L28.6426 93.4086L41.8141 93.4343C42.1158 93.4348 42.3575 93.1938 42.3581 92.8919L42.3848 79.2055C42.3854 78.9036 42.1445 78.6616 41.8429 78.6611L28.5306 78.6351L28.5547 66.2771C28.5553 65.9752 28.3144 65.7332 28.0128 65.7327L14.3385 65.706C14.0369 65.7054 13.7951 65.9465 13.7945 66.2484L13.7685 79.5926L0.59692 79.5669C0.295281 79.5663 0.0535216 79.8074 0.0529333 80.1093L0.0263022 93.7755C0.0257139 94.0774 0.266532 94.3194 0.568171 94.32'
                  fill='#FFCD00'
                />
              </svg>
            </div>
          )}
        </div>
        <m.div
          initial='hidden'
          ref={cardContainerRef}
          animate={isInView ? 'visible' : 'hidden'}
          variants={containerAnimation}
          className={`${
            heading ? 'mt-6 lg:mt-14' : ''
          } relative z-10 grid items-stretch gap-8 md:grid-cols-2 md:gap-6 lg:grid-cols-3 lg:gap-8 xl:gap-10 2xl:gap-12`}
        >
          {cards &&
            cards.length > 0 &&
            cards.map((card, index) => (
              <m.div
                variants={cardAnimation}
                key={`${sectionKey}-card-${index}`}
              >
                <UniqueSellingPointsCard card={card} />
              </m.div>
            ))}
        </m.div>
      </div>
      {displayOptions?.showPattern && (
        <>
          <div className='absolute inset-x-0 bottom-24 hidden h-28 flex-shrink-0 overflow-hidden bg-yellow-100 lg:block'>
            <Image
              className='h-full w-full min-w-[1920px] flex-shrink-0 object-cover opacity-70'
              src={Pattern}
              alt={''}
              width={1920}
              height={100}
              loading='lazy'
            />
          </div>
        </>
      )}
    </section>
  );
}
